import { styled } from "@mui/material/styles";
import { Box } from "@mui/material";

export const Container = styled(Box)`
  display: block;
  width: 100%;
  border-radius: ${(props) => props.theme.shape.borderRadius}px;
  border: 1px solid ${(props) => props.theme.palette.divider};
  padding: ${(props) => props.theme.spacing(2)};
`;

export const Header = styled("div")`
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-template-rows: 1fr;
  grid-column-gap: ${(props) => props.theme.spacing(1)};
  grid-row-gap: 0;
`;

export const Info = styled("div")`
  display: inline-grid;
  align-items: center;
`;

export const Progress = styled("div")`
  display: flex;
  align-items: center;
  gap: 0;

  .MuiLinearProgress-root {
    width: 100%;
  }

  .MuiTypography-root {
    width: 34px;
    text-align: right;
  }
`;

export const IconBox = styled("span")`
  display: flex;
  background-color: ${(props) => props.theme.palette.grey.A100};
  padding: ${(props) => props.theme.spacing(1)};
  border-radius: 50%;
  min-width: 24px;
  min-height: 24px;
  align-items: center;
  justify-content: center;

  & svg {
    vertical-align: middle;
  }
`;
