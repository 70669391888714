import React from "react";
import ReactDOM from "react-dom/client";
import { ThemeProvider } from "@mui/material";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import getTheme from "@bavatech/theme";
import Application from "@/Application";

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <ThemeProvider theme={getTheme()}>
    <DndProvider backend={HTML5Backend}>
      <Application />
    </DndProvider>
  </ThemeProvider>
);
