import { IconButton, TextField } from "@mui/material";
import { SxProps, Theme } from "@mui/system";
import { Copy24Regular } from "@fluentui/react-icons";

// 'Copiado para área de transferência.'

interface CopyInputProps {
  label: string;
  value?: string;
  readonly?: boolean;
  disabled?: boolean;
  sx?: SxProps<Theme>;
  onCopy?: () => void;
}

export default function CopyInput({
                                    label,
                                    value,
                                    readonly = true,
                                    disabled = false,
                                    onCopy,
                                    sx
                                  }: CopyInputProps) {
  return (
    <TextField
      sx={sx}
      fullWidth
      type="text"
      label={label}
      value={value}
      disabled={disabled}
      InputProps={{
        readOnly: readonly,
        endAdornment: (
          <IconButton
            color="inherit"
            onClick={() => {
              navigator.clipboard.writeText(value || "")
                .then(() => {
                  if (onCopy) {
                    onCopy();
                  }
                })
                .catch(console.warn);
            }}
          ><Copy24Regular /></IconButton>
        )
      }}
    />
  );
}
