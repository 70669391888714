import { Buffer } from "buffer";

function hexString(buffer: ArrayBuffer) {
  const hexCodes: string[] = Array.from(new Uint8Array(buffer)).map(value =>
    value.toString(16).padStart(2, "0")
  );

  return hexCodes.join("");
}

async function fileToArrayBuffer(file: File): Promise<ArrayBuffer> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.addEventListener("load", () => {
      resolve(reader.result as ArrayBuffer);
    });

    reader.addEventListener("error", reject);

    reader.readAsArrayBuffer(file);
  });
}

async function bufferToSha256(buffer: ArrayBuffer): Promise<ArrayBuffer> {
  return window.crypto.subtle.digest("SHA-256", buffer);
}

/**
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Web_Crypto_API
 */
export async function fileToSha256Hex(file: File): Promise<string> {
  const buffer = await fileToArrayBuffer(file);
  const hash = await bufferToSha256(Buffer.from(buffer));
  return hexString(hash).toUpperCase();
}

