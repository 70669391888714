import React, { ChangeEvent, useCallback, useRef, useState } from "react";
import { Box, Container, Stack, Typography } from "@mui/material";
import { NativeTypes } from "react-dnd-html5-backend";
import { useDrop } from "react-dnd";
import { v4 as uuidv4 } from "uuid";
import { FileAction, FileWithId } from "@/types";
import { Area, DropArea, DropInput } from "@/styles";
import { Archive32Regular } from "@fluentui/react-icons";
import FileRow from "@/components/FileRow";

export default function Application() {
  const [files, setFiles] = useState<FileWithId[]>([]);
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop(item: { files: File[] }) {
        handleFileDrop({ files: item.files });
      },
      collect(monitor) {
        return {
          canDrop: monitor.canDrop(),
          isOver: monitor.isOver()
        };
      }
    }),
    []
  );

  const handleFileDrop = useCallback(
    (item: { files: File[] }) => {
      if (item) {
        const files = item.files.map((file) => ({
          content: file,
          id: uuidv4(),
          action: FileAction.Idle
        }));

        setFiles((state) => [...state, ...files]);
      }
    },
    []
  );

  const hasFiles = files.length > 0;
  const className = [];
  if (canDrop && isOver) {
    className.push("is-active");
  }

  if (canDrop) {
    className.push("can-drop");
  }

  if (isOver) {
    className.push("is-over");
  }

  const onClick = (event: React.MouseEvent<HTMLDivElement>): void => {
    event.preventDefault();

    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  const onDelete = (file: FileWithId) => {
    setFiles((state) => state.filter((row) => row.id !== file.id));
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    if (event.target.files?.length) {
      handleFileDrop({
        files: Array.from(event.target.files)
      });
    }
  };

  return (
    <>
      <DropInput
        type="file"
        ref={hiddenFileInput}
        onChange={handleChange}
        multiple
        value=""
      />

      <Container maxWidth="md">
        <Stack direction="column" spacing={3} sx={{ py: 3 }}>
          <Box>
            <img
              src="https://static.libercapital.com.br/vendors/3358b1cb-5312-4827-b4e6-9c71e4124cd4/logos/logo.svg"
              alt="Logo Liber Capital"
              height="40px"
            />
          </Box>
          <Area>
            <Stack direction="column" spacing={2}>
                <Typography variant="h1">Validador de checksum</Typography>
                <Typography paragraph sx={{ m: 0 }}>
                  Checksum é o nome dado ao procedimento de verificação da autenticidade e integridade de um determinado arquivo, podendo este ser uma foto, imagem de disco, áudio, vídeo, documento de texto ou virtualmente qualquer outro formato de arquivo digital que você possa ter em seu computador.
                </Typography>
                <Typography paragraph sx={{ m: 0 }}>
                  Após ter feito o download do arquivo alvo, você pode verificar a autenticidade e integridade do arquivo por essa ferramenta. E, caso ambos sejam idênticos você terá a prova de que o arquivo baixado não foi de alguma forma corrompido ou alterado.
                </Typography>
              <DropArea className={className.join(" ")} ref={drop} onClick={onClick}>
                {!hasFiles && (
                  <>
                    <Typography color="primary.main">
                      <Archive32Regular fontSize="large" />
                    </Typography>
                    <Typography color="primary">Selecionar ou arraste aqui arquivos</Typography>
                  </>
                )}

                {hasFiles && (
                  <Typography color="primary">Selecionar mais arquivos</Typography>
                )}
              </DropArea>

              {files.map((file) => (
                <FileRow
                  key={file.id}
                  start={() => {
                  }}
                  file={file}
                  onDelete={onDelete}
                />
              ))}
            </Stack>
          </Area>
        </Stack>
      </Container>
    </>
  );
}
