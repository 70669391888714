export enum FileAction {
  Idle,
  Start,
}

export interface FileWithId {
  action: FileAction;
  id: string;
  content: File;
}

export enum State {
  Processing = "Processing",
  Idle = "Idle",
  Checking = "Checking",
  Success = "Success",
  Fail = "Fail",
  Error = "Error",
}

export type Color = "primary" | "secondary" | "success" | "error" | "info" | "warning";
