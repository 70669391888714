import { useEffect, useMemo, useState } from "react";
import { Document32Filled } from "@fluentui/react-icons";
import Typography from "@mui/material/Typography";
import { Container, Header, Info } from "./styles";
import { Color, FileWithId, State } from "@/types";
import ActionsAndStatus from "@/components/ActionsAndStatus";
import { fileToSha256Hex } from "@/utils";
import { check } from "@/api";
import CopyInput from "@/components/CopyInput";
import { Stack } from "@mui/material";

interface FileRowProps {
  file: FileWithId;
  start: () => void;
  onDelete: (file: FileWithId) => void;
}

export default function FileRow({ file, onDelete }: FileRowProps) {
  const [checksum, setChecksum] = useState<string>("");
  const [state, setState] = useState<State>(State.Processing);

  const color: Color = useMemo(() => {
    switch (state) {
      case State.Success:
        return "success";
      case State.Fail:
        return "error";
      case State.Error:
        return "warning";
      default:
        return "primary";
    }
  }, [state]);

  useEffect(() => {
    fileToSha256Hex(file.content)
      .then((value) => {
        setChecksum(() => value);
        setState(() => State.Idle);
      })
      .catch(console.error);
  }, []);

  const onCheck = () => {
    setState(() => State.Checking);
    check(checksum)
      .then(({ data }) => {
        setState(() => data.valid ? State.Success : State.Fail);
      })
      .catch(() => {
        setState(State.Error);
      });
  };

  const borderColor = useMemo(() => {
    if (state === State.Success) {
      return "success.main";
    }

    if (state === State.Fail) {
      return "error.main";
    }

    if (state === State.Error) {
      return "warning.main";
    }

    return "divider";
  }, [state]);

  return (
    <Container sx={{ borderColor }}>
      <Stack direction="column" spacing={2}>
        <Header>
          <Typography color={`${color}.main`}>
            <Document32Filled />
          </Typography>
          <Info>
            <Typography noWrap>{file.content.name}</Typography>
          </Info>
          <ActionsAndStatus
            state={state}
            color={color}
            onDelete={() => {
              onDelete(file);
            }}
            onCheck={() => {
              onCheck();
            }}
          />
        </Header>

        <CopyInput label="Checksum SHA-256" value={checksum} />
      </Stack>
    </Container>
  );
}
