import { alpha, styled } from "@mui/material";

export const Area = styled("div")`
  display: block;
  background: white;
  border-radius: ${(props) => props.theme.shape.borderRadius}px;
  box-shadow: 0 1px 1px 0 #50628638;
  padding: ${(props) => props.theme.spacing(2)};
`;

export const DropArea = styled("div")`
  box-sizing: content-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${(props) => props.theme.spacing(4, 2)};
  background-color: ${(props) => props.theme.palette.background.paper};
  border: 2px solid ${(props) => props.theme.palette.grey.A100};
  border-radius: ${(props) => `${props.theme.shape.borderRadius}px`};
  cursor: pointer;

  &.can-drop {
    border-color: ${(props) => props.theme.palette.primary.main};
  }

  &:hover, &.is-over {
    border-color: ${(props) => props.theme.palette.primary.main};
    background-color: ${(props) => alpha(props.theme.palette.primary.light, 0.1)};
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    min-width: 480px;
  }
`;

export const DropInput = styled("input")`
  display: none;
`;

