import axios, { AxiosRequestConfig } from "axios";
import { ENDPOINT_URL } from "@/env";

const api = axios.create({
  baseURL: ENDPOINT_URL
});

export const check = (checksum: string, opts?: AxiosRequestConfig) => {
  return api.get<{ valid: boolean }>(
    "/v1/external_reports/validate",
    { ...opts, params: { checksum } }
  );
};
